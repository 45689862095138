import marker_1_0 from '@/assets/image/marker-icon-1-disable.png';  // 商砼站 - 未入驻
import marker_1_1 from '@/assets/image/marker-icon-1.png';          // 商砼站 - 已入驻
import marker_1_2 from '@/assets/image/marker-icon-color-1.png';          // 商砼站 - 已入驻 - 1
import marker_1_3 from '@/assets/image/marker-icon-color-2.png';          // 商砼站 - 已入驻 - 2
import marker_1_4 from '@/assets/image/marker-icon-color-3.png';          // 商砼站 - 已入驻 - 3
import marker_2_0 from '@/assets/image/marker-icon-2-0.png'         // 司机 - 未点亮
import marker_2_1 from '@/assets/image/marker-icon-2-1.png'         // 司机 - 点量
import marker_3__1 from '@/assets/image/marker-icon-level--1.png'   // 矿山 - 等级-1
import marker_3_0 from '@/assets/image/marker-icon-level-0.png'     // 矿山 - 等级0
import marker_3_1 from '@/assets/image/marker-icon-level-1.png'     // 矿山 - 等级1
import marker_3_2 from '@/assets/image/marker-icon-level-2.png'     // 矿山 - 等级2
import marker_3_3 from '@/assets/image/marker-icon-level-3.png'     // 矿山 - 等级3

import mine_center from '@/assets/image/mine-center.png'            // 矿山详情 - 中心点
import mine_center_new from '@/assets/image/mine-center-new.png'            // 矿山详情 - 中心点 - 新的
import dirver_home from '@/assets/image/home.png'                   // 司机 - 家
import marker_4_1 from '@img/party-icon-1.png'            // 项目方


// 司机轨迹的坐标点
import dirver_1 from '@/assets/image/type/1.png'
import dirver_2 from '@/assets/image/type/2.png'
import dirver_3 from '@/assets/image/type/3.png'
import dirver_4 from '@/assets/image/type/4.png'
import dirver_5 from '@/assets/image/type/5.png'
import dirver_6 from '@/assets/image/type/6.png'
import dirver_7 from '@/assets/image/type/7.png'
import dirver_8 from '@/assets/image/type/8.png'
import dirver_9 from '@/assets/image/type/9.png'
import dirver_10 from '@/assets/image/type/10.png'

// 距离等级
import marker_d_1 from '@/assets/image/distance/1.png'
import marker_d_2 from '@/assets/image/distance/2.png'
import marker_d_3 from '@/assets/image/distance/3.png'
import marker_d_4 from '@/assets/image/distance/4.png'
import marker_d_5 from '@/assets/image/distance/5.png'

let icons = {
  type_1_0:marker_1_0,
  type_1_1:marker_1_1,
  type_1_2:marker_1_2,
  type_1_3:marker_1_3,
  type_1_4:marker_1_4,
  type_2_0:marker_2_0,
  type_2_1:marker_2_1,
  type_3__1:marker_3__1,
  type_3_0:marker_3_0,
  type_3_1:marker_3_1,
  type_3_2:marker_3_2,
  type_3_3:marker_3_3,
  type_4_1:marker_4_1,
  mine_center:mine_center,
  mine_center_new:mine_center_new,
  dirver_home:dirver_home,
  dirver_1:dirver_1,
  dirver_2:dirver_2,
  dirver_3:dirver_3,
  dirver_4:dirver_4,
  dirver_5:dirver_5,
  dirver_6:dirver_6,
  dirver_7:dirver_7,
  dirver_8:dirver_8,
  dirver_9:dirver_9,
  dirver_10:dirver_10,
  marker_d_1:marker_d_1,
  marker_d_2:marker_d_2,
  marker_d_3:marker_d_3,
  marker_d_4:marker_d_4,
  marker_d_5:marker_d_5
}

export default icons;